import loaderStyles from './loaderStyles.module.css'

const Loader = () => {
  return (
    <div className={loaderStyles.loader}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
