import { createSlice } from '@reduxjs/toolkit'
import { IPagedEntityCollection } from '@mpe/api-client/types'
import { createModelListFromData } from '@mpe/api-client/utils/dataConverter'
import { statuses } from '@/consts/apiStatuses'
import { RootState } from '../rootReducer'
import { AppThunk } from '../index'
import { getToken } from '../thunks/videoPlaylistsThunk'

interface FavoritesState {
  pagedFavorites: {
    status: string
    data: IPagedEntityCollection | null
  }
  isLoading: boolean
  loadingEntity: string | null
  favorites: string[]
}

const initialState: FavoritesState = {
  pagedFavorites: {
    status: statuses.UNLOADED,
    data: null
  },
  isLoading: false,
  loadingEntity: null,
  favorites: []
}

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setPagedFavoritesError: (state) => {
      state.pagedFavorites.data = null
      state.pagedFavorites.status = statuses.ERROR
    },
    setPagedFavoritesRequest: (state) => {
      state.pagedFavorites.data = null
      state.pagedFavorites.status = statuses.LOADING
    },
    setPagedFavoritesSuccess: (state, { payload }) => {
      state.pagedFavorites.data = payload
      state.pagedFavorites.status = statuses.LOADED
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload.isLoading
      state.loadingEntity = payload.loadingEntity ?? null
    },
    setFavorites: (state, { payload }) => {
      state.favorites = [...payload]
      state.isLoading = false
      state.loadingEntity = null
    }
  }
})

const {
  setPagedFavoritesRequest,
  setPagedFavoritesError,
  setPagedFavoritesSuccess,
  setLoading,
  setFavorites
} = favoritesSlice.actions

export const getPagedFavorites =
  (page = 1): AppThunk =>
  async (dispatch: any) => {
    dispatch(setPagedFavoritesRequest())

    const token = getToken()

    if (!token) {
      dispatch(setPagedFavoritesError())
    }

    try {
      const response = await fetch(`/api/favorites?page=${page}`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (200 === response.status) {
        const data = await response.json()
        const items = createModelListFromData(data.items)

        dispatch(setPagedFavoritesSuccess({ ...data, items }))
      }
    } catch (error) {
      console.log(error)
    }

    try {
      const response = await fetch(`/api/favorites?page=${page}`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (200 === response.status) {
        const data = await response.json()
        const items = createModelListFromData(data.items)
        dispatch(setPagedFavoritesSuccess({ ...data, items }))
      }
    } catch (error) {
      console.log(error)
    }

    dispatch(setPagedFavoritesError())
  }

export const updateFavorites =
  (favorites: string[]): AppThunk =>
  async (dispatch: any) => {
    dispatch(setFavorites(favorites))
  }

export const toggleFavorite =
  (entity: string): AppThunk =>
  async (dispatch: any) => {
    dispatch(setLoading({ isLoading: true, loadingEntity: entity }))

    const token = getToken()

    try {
      const response = await fetch('/api/toggle-favorite', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ entity })
      })

      if (200 === response.status) {
        const data = await response.json()

        dispatch(setFavorites(data.favorites ?? []))

        return
      }
    } catch (error) {
      console.log(error)
    }

    dispatch(setLoading({ isLoading: false }))
  }

export const favoritesSelector = (state: RootState) => state.favorites
export const isEntityFavoritedSelector = (entity: string) => (state: RootState) => {
  const isFavorited = state.favorites.favorites.includes(entity)

  // show the final state when toggle favorite is loading, so the user wont see any glitch
  if (state.favorites.isLoading) {
    return isFavorited
      ? entity !== state.favorites.loadingEntity
      : entity === state.favorites.loadingEntity
  }

  return isFavorited
}

export default favoritesSlice.reducer
