import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../rootReducer'
import { AppThunk } from '..'

type ToasterTypes = 'success' | 'fail' | 'info'
export const success: ToasterTypes = 'success'
export const fail: ToasterTypes = 'fail'
export const info: ToasterTypes = 'info'

export interface Toaster {
  type: ToasterTypes
  message: string
}

interface ToasterState {
  content: Toaster[]
}

const initialState: ToasterState = {
  content: []
}

const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    show: (state, { payload }) => {
      state.content?.push(payload)
    },
    hide: (state) => {
      state.content.shift()
    }
  }
})

const { show, hide } = toasterSlice.actions

export const showToaster =
  (type: ToasterTypes, message: string): AppThunk =>
  async (dispatch: any) => {
    dispatch(show({ type, message }))
    setTimeout(() => dispatch(hide()), 5000)
  }

export const toasterSelector = (state: RootState) => state.toaster
export default toasterSlice.reducer
