import { createSlice } from '@reduxjs/toolkit'

import { AppThunk } from '../index'
import { RootState } from '../rootReducer'
import { getToken } from '../thunks/videoPlaylistsThunk'
import { resetSelectedPlaylist, stopSelectedPlaylist } from './audioPlayerSlice'
import { updateFavorites } from './favoritesSlice'

export interface AuthState {
  isError: boolean
  isAuth: boolean
  currentUser?: CurrentUser
  isLoading: boolean
  error: string
}

export interface CurrentUser {
  name: string
  email: string
  token: string
  source?: string
  policyAccepted?: string
  policyUpdated?: string
}

export const initialState: AuthState = {
  isError: false,
  isAuth: false,
  isLoading: true,
  error: ''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setAuthSuccess: (state, { payload }) => {
      state.currentUser = payload
      state.isAuth = true
      state.isLoading = false
      state.error = ''
    },
    setLogOut: (state) => {
      state.isAuth = false
      state.currentUser = undefined
      state.error = ''
    },
    setAuthFailed: (state, { payload }) => {
      state.error = payload
      state.isError = true
      state.isAuth = false
    },
    resetError: (state) => {
      state.error = ''
      state.isError = false
    }
  }
})

export const login =
  (values: { email: string; password: string }): AppThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true))
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(values)
      })
      if (response.status === 200) {
        const data = await response.json()
        const { token, user, favorites } = data

        localStorage.setItem('user_token', token)

        dispatch(setAuthSuccess(user))
        dispatch(updateFavorites(favorites ?? []))
      } else {
        dispatch(setAuthFailed('Felhasználónév vagy jelszó hibás'))
        dispatch(updateFavorites([]))
      }
    } catch (error) {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      dispatch(setAuthFailed(message))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const facebookLogin =
  (values: { accessToken: string }): AppThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true))
      const response = await fetch('/api/facebook-login', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(values)
      })
      if (response.status === 200) {
        const data = await response.json()
        const { token, user, favorites } = data

        localStorage.setItem('user_token', token)

        dispatch(setAuthSuccess(user))
        dispatch(updateFavorites(favorites ?? []))
      } else {
        dispatch(setAuthFailed('Felhasználónév vagy jelszó hibás'))
        dispatch(updateFavorites([]))
      }
    } catch (error) {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      dispatch(setAuthFailed(message))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const logout = (): AppThunk => async (dispatch: any) => {
  try {
    const token = getToken()

    const response = await fetch('/api/logout', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }
    })

    if (204 === response.status) {
      dispatch(stopSelectedPlaylist())
      dispatch(resetSelectedPlaylist())
      dispatch(setLogOut())
      localStorage.removeItem('user_token')
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export const getUser = (): AppThunk => async (dispatch: any) => {
  const token = localStorage.getItem('user_token')

  if (!token) {
    dispatch(setLoading(false))
    return
  }

  try {
    dispatch(setLoading(true))
    const response = await fetch('/api/user', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }
    })

    if (401 === response.status) {
      localStorage.removeItem('user_token')

      dispatch(setAuthFailed('Unauthorized'))
      dispatch(updateFavorites([]))

      return
    }

    if (200 === response.status) {
      const data = await response.json()
      const { user, favorites } = data

      dispatch(setAuthSuccess(user))
      dispatch(updateFavorites(favorites))
    }
  } catch (error) {
    dispatch(setAuthFailed(error instanceof Error ? error.message : ''))
    dispatch(updateFavorites([]))

    console.error(error)
  }
}

export const { setAuthSuccess, setLogOut, setLoading, setAuthFailed, resetError } =
  authSlice.actions
export const authSelector = (state: RootState) => state.auth
export default authSlice.reducer
