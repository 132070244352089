import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (SENTRY_DSN !== '') {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.1,
    beforeSend(event) {
      if (window.navigator.userAgent.toLowerCase().includes('kube-probe')) {
        return null
      }
      return event
    }
  })
}
