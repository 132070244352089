import React, { CSSProperties, FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import NextLink from 'next/link'

type Props = {
  href: string
  passHref?: boolean
  locale?: string | false
  children: ReactNode
  title?: string
  download?: string
  onClick?: (event: any) => void
  [key: string]: any
  useHref?: boolean
  style?: CSSProperties
}

export const Link: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  href,
  locale,
  passHref = true,
  useHref = true,
  ...props
}: Props) => {
  return useHref ? (
    <NextLink href={href} locale={locale} passHref={passHref}>
      <a {...props}>{children}</a>
    </NextLink>
  ) : (
    <span {...props}>{children}</span>
  )
}
