import { createSlice } from '@reduxjs/toolkit'
import { AudioPlaylist } from '@mpe/api-client/models/audio-playlist'

import { RootState } from '../rootReducer'
import { statuses } from '@/consts/apiStatuses'

export interface PlaylistsState {
  status: string
  data: AudioPlaylist[]
}

const initialState: PlaylistsState = {
  status: statuses.UNLOADED,
  data: []
}

export const audioPlaylistsSlice = createSlice({
  name: 'audioPlaylists',
  initialState,
  reducers: {
    getAudioPlaylistSuccess: (state, { payload }) => {
      const playlistIndex = state.data.findIndex((p) => p['@id'] === payload['@id'])
      if (playlistIndex > -1) {
        state.data[playlistIndex] = payload
      }
      state.status = statuses.LOADED
    },
    audioPlaylistRequest: (state) => {
      state.status = statuses.LOADING
    },
    createAudioPlaylistSuccess: (state) => {
      state.status = statuses.LOADED
    },
    createAudioPlaylistError: (state) => {
      state.status = statuses.ERROR
    },
    getAudioPlaylistAuthenticationError: (state) => {
      state.status = statuses.AUTHENTICATION_ERROR
    },
    addAudioPlaylistItemSuccess: (state, { payload }) => {
      state.status = statuses.LOADED
      state.data = payload || []
    },
    getAudioPlaylistsSuccess: (state, { payload }) => {
      state.status = statuses.LOADED
      state.data = payload
    },
    getAudioPlaylistError: (state) => {
      state.status = statuses.ERROR
    },
    deleteAudioPlaylistSuccess: (state) => {
      state.status = statuses.LOADED
      state.data = []
    },
    deleteAudioPlaylistItemSuccess: (state, { payload }) => {
      state.status = statuses.LOADED
      const playlistIndex = state.data.findIndex((pl) => pl.tracks.find((tr) => tr.id === payload))
      if (playlistIndex > -1) {
        const trackIndex = state.data[playlistIndex].tracks.findIndex((tr) => tr.id === payload)
        state.data[playlistIndex].tracks.splice(trackIndex, 1)
      }
    },
    updateAudioPlaylistItemSuccess: (state, { payload: { id, name, isPublic } }) => {
      state.status = statuses.LOADED
      const index = state.data.findIndex((p) => String(p.id) === String(id))
      if (index > -1) {
        state.data[index].name = name
        state.data[index].public = isPublic
      }
    }
  }
})

export const {
  audioPlaylistRequest,
  createAudioPlaylistSuccess,
  createAudioPlaylistError,
  deleteAudioPlaylistSuccess,
  getAudioPlaylistError,
  getAudioPlaylistSuccess,
  getAudioPlaylistAuthenticationError,
  deleteAudioPlaylistItemSuccess,
  addAudioPlaylistItemSuccess,
  updateAudioPlaylistItemSuccess,
  getAudioPlaylistsSuccess
} = audioPlaylistsSlice.actions

export const audioPlaylistsSelector = (state: RootState) => {
  return state.audioPlaylists
}
export default audioPlaylistsSlice.reducer
