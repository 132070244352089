import { SyntheticEvent, useState } from 'react'
import getConfig from 'next/config'

import { useAppDispatch } from '@/store/index'
import { getUser } from '@/store/slices/authSlice'
import { forceHide } from '@/store/slices/modalSlice'

import Button from '../Button'
import { Link } from '../Link/Link'

export default function PrivacyPolicyModal() {
  const { privacyPolicyPdf } = getConfig().publicRuntimeConfig ?? {}
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const dispatch = useAppDispatch()

  const onAcceptClick = async () => {
    const acceptInput: any = document.getElementById('policy_accepted')
    if (!acceptInput.checked) {
      setError('Előbb el kell fogadnod az adatkezelési nyilatkozatot!')
      return
    }

    setLoading(true)
    setError('')

    try {
      const token = localStorage.getItem('user_token')

      const response = await fetch('/api/user', {
        method: 'POST',
        headers: { 'content-type': 'application/json', authorization: `Bearer ${token}` },
        body: JSON.stringify({ policyAccepted: true })
      })

      if (200 === response.status) {
        dispatch(getUser())
      }

      dispatch(forceHide())
    } catch (e) {
      setError('Hiba az elfogadás során. Kérlek próbáld újra később')
    }

    setLoading(false)
  }

  const onAcceptChange = (e: SyntheticEvent) => {
    if ((e.target as any).checked && error.length) {
      setError('')
    }
  }

  const advanceButtonText = loading ? 'Elfogadás...' : 'Tovább'

  return (
    <div className="flex flex-col w-full overflow-hidden shadow-lg bg-gradient-to-tr from-skyBlue to-indigo p-5 gap-4">
      <h2 className="font-bold">Adatkezelési Nyilatkozat</h2>
      <div className="bg-white p-5 text-black">
        <h3 className="mb-6">
          A <b>MPÉ</b> használata előtt el kell fogadnod az{' '}
          <Link
            href={privacyPolicyPdf || ''}
            className="underline hover:text-skyBlue"
            target={'_blank'}
          >
            adatkezelési nyilatkozat
          </Link>
          ot.
        </h3>
        <div className="flex flex-row gap-1">
          <input
            type="checkbox"
            id="policy_accepted"
            className="scale-125 transform-gpu mr-2"
            onChange={onAcceptChange}
          />
          <label htmlFor="policy_accepted" className="cursor-pointer">
            Elolvastam és elfogadom az adatkezelési nyilatkozatot
          </label>
        </div>
        {!!error.length && <p className="text-red mb-2">{error}</p>}
        <div>
          <Button
            className="mt-5 mx-auto w-40 bg-gradient-to-b from-indigo to-skyBlue hover:from-skyBlue hover:to-indigo text-white"
            onClick={onAcceptClick}
          >
            {advanceButtonText}
          </Button>
        </div>
      </div>
    </div>
  )
}
