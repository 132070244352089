import { useEffect, useRef, useState } from 'react'
import NextImage, { ImageLoaderProps } from 'next/image'

import Loader from '../Loader'

import { imageWithWidth } from '@/utils'

interface ImageProps {
  src: string
  className?: string
  position?: string
  priority?: boolean
  contain?: boolean
  mediaSource?: string
  useSrcLoader?: boolean
  showLoader?: boolean
}

const classes = {
  mediaSourceContainer:
    'w-full absolute bottom-0 right-0 p-4 pt-8 text-sm opacity-80 text-center bg-gradient-to-t from-black via-transparent to-transparent z-20'
}

export default function CustomImage({
  src,
  className,
  priority,
  contain,
  position,
  mediaSource,
  useSrcLoader,
  showLoader
}: ImageProps) {
  const loadOffset = 1000 // Start loading when scrolling within 1000px of the image.
  const imageWrapper = useRef<HTMLDivElement | null>(null)
  const [forceShow, setForceShow] = useState(false)
  const [loadingSign, setLoadingSign] = useState(!!showLoader)

  useEffect(() => {
    if (showLoader) {
      const img = new Image()

      img.src = useSrcLoader ? src : imageWithWidth(src, 1920)
      img.onload = () => setLoadingSign(false)
    }
  }, [])

  useEffect(() => {
    const imageObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]

        if (entry.isIntersecting) {
          setForceShow(true)
          imageObserver.unobserve(entry.target)
        }
      },
      {
        rootMargin: `${loadOffset}px`
      }
    )

    if (!priority && process.browser && 'IntersectionObserver' in window && imageWrapper?.current) {
      imageObserver.observe(imageWrapper.current)
    }

    return () => imageObserver.disconnect()
  }, [priority, imageWrapper, process.browser])

  const srcLoader = ({ src }: ImageLoaderProps): string => src

  const objectFit = contain ? 'contain' : 'cover'
  const objectPosition = position ? position : contain ? 'top center' : 'center center'

  return src ? (
    <div ref={imageWrapper}>
      <NextImage
        src={src}
        layout="fill"
        className={className}
        priority={priority || forceShow}
        objectFit={objectFit}
        objectPosition={objectPosition}
        loader={useSrcLoader ? srcLoader : undefined}
      />

      {loadingSign && (
        <div className="absolute top-0 left-0 h-full w-full z-30">
          <div className="relative flex h-full w-full items-center justify-center">
            <Loader />
          </div>
        </div>
      )}

      {mediaSource ? (
        <div className={classes.mediaSourceContainer}>Forrás: {mediaSource}</div>
      ) : null}
    </div>
  ) : null
}
