import { combineReducers } from '@reduxjs/toolkit'

import authSlice from './slices/authSlice'
import favoritesSlice from './slices/favoritesSlice'
import modalSlice from './slices/modalSlice'
import navigationSlice from './slices/navigationSlice'
import tracksSlice from './slices/tracksSlice'
import toasterSlice from './slices/toasterSlice'
import videoPlaylistsSlice from './slices/videoPlaylistsSlice'
import videoPlayerSlice from './slices/videoPlayerSlice'
import audioPlaylistsSlice from './slices/audioPlaylistsSlice'
import audioPlayerSlice from './slices/audioPlayerSlice'

import store from './index'

const rootReducer = combineReducers({
  auth: authSlice,
  favorites: favoritesSlice,
  modal: modalSlice,
  navigation: navigationSlice,
  audioPlaylists: audioPlaylistsSlice,
  audioPlayer: audioPlayerSlice,
  tracks: tracksSlice,
  toaster: toasterSlice,
  videoPlaylists: videoPlaylistsSlice,
  videoPlayer: videoPlayerSlice
})

export type RootState = ReturnType<typeof store.getState>

export default rootReducer
