let dataLayer: any

const useDataLayer = () => {
  if (!dataLayer) {
    try {
      dataLayer = (window as any).dataLayer || []
    } catch (e) {
      dataLayer = []
    }
  }
  return dataLayer
}

export default useDataLayer
