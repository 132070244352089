import AddToPlaylist from '../public/icons/add-to-playlist.svg'
import AddToVideoPlaylist from '../public/icons/add-to-videoplaylist.svg'
import Album from '../public/icons/album.svg'
import ArchBig from '../public/icons/arch-big.svg'
import ArchSmall from '../public/icons/arch-small.svg'
import ArrowLeft from '../public/icons/arrow-left.svg'
import ArrowLeftSmall from '../public/icons/arrow-left-small.svg'
import ArrowRight from '../public/icons/arrow-right.svg'
import ArrowRightSmall from '../public/icons/arrow-right-small.svg'
import Audio from '../public/icons/audio.svg'
import Award from '../public/icons/award.svg'
import Band from '../public/icons/band.svg'
import Building from '../public/icons/building.svg'
import ButtonSearch from '../public/icons/button-search.svg'
import Cancel from '../public/icons/cancel.svg'
import Caret from '../public/icons/caret.svg'
import CheckMark from '../public/icons/check-mark.svg'
import Clock from '../public/icons/clock.svg'
import Disc from '../public/icons/disc.svg'
import Email from '../public/icons/email.svg'
import Era from '../public/icons/era.svg'
import EventSeries from '../public/icons/event-series.svg'
import Event from '../public/icons/event.svg'
import Exit from '../public/icons/exit.svg'
import Expand from '../public/icons/expand.svg'
import Globe from '../public/icons/globe.svg'
import Image from '../public/icons/image.svg'
import ImageColored from '../public/icons/image-colored.svg'
import Info from '../public/icons/info.svg'
import Lock from '../public/icons/lock.svg'
import MpeLogoColor from '../public/icons/mpe-logo-color.svg'
import MpeLogoWhite from '../public/icons/mpe-logo-white.svg'
import MicrophoneColored from '../public/icons/microphone-colored.svg'
import Musician from '../public/icons/musician.svg'
import Next from '../public/icons/next.svg'
import Pause from '../public/icons/pause.svg'
import PauseCircle from '../public/icons/pause-circle.svg'
import Person from '../public/icons/person.svg'
import Play from '../public/icons/play.svg'
import PlayBoldWhite from '../public/icons/play-bold-white.svg'
import PlayCircle from '../public/icons/play-circle.svg'
import PlayColored from '../public/icons/play-colored.svg'
import Plus from '../public/icons/plus.svg'
import Publication from '../public/icons/publication.svg'
import PublicationItem from '../public/icons/publicationItem.svg'
import Record from '../public/icons/record.svg'
import Search from '../public/icons/search.svg'
import Song from '../public/icons/song.svg'
import Star from '../public/icons/star.svg'
import Tag from '../public/icons/tag.svg'
import Tick from '../public/icons/tick.svg'
import Trash from '../public/icons/trash.svg'
import Turntable from '../public/icons/turntable.svg'
import User from '../public/icons/user.svg'
import UserSm from '../public/icons/user-sm.svg'
import Venue from '../public/icons/venue.svg'
import Video from '../public/icons/video.svg'
import WaveColored from '../public/icons/wave-colored.svg'
import EnglishFlag from '../public/icons/english-flag.svg'
import HungarianFlag from '../public/icons/hungarian-flag.svg'
import Edit from '../public/icons/edit.svg'
import RotateLeft from '../public/icons/rotate-left.svg'

export const iconMapping: { [name: string]: JSX.Element } = {
  'add-to-playlist': <AddToPlaylist />,
  'add-to-video-playlist': <AddToVideoPlaylist />,
  album: <Album />,
  'arch-big': <ArchBig />,
  'arch-small': <ArchSmall />,
  'arrow-left': <ArrowLeft />,
  'arrow-left-small': <ArrowLeftSmall />,
  'arrow-right': <ArrowRight />,
  'arrow-right-small': <ArrowRightSmall />,
  audio: <Audio />,
  award: <Award />,
  band: <Band />,
  building: <Building />,
  'button-search': <ButtonSearch />,
  caret: <Caret />,
  'check-mark': <CheckMark />,
  cancel: <Cancel />,
  clock: <Clock />,
  disc: <Disc />,
  email: <Email />,
  edit: <Edit />,
  'english-flag': <EnglishFlag />,
  'hungarian-flag': <HungarianFlag />,
  era: <Era />,
  'event-series': <EventSeries />,
  event: <Event />,
  exit: <Exit />,
  expand: <Expand />,
  globe: <Globe />,
  info: <Info />,
  image: <Image />,
  'image-colored': <ImageColored />,
  lock: <Lock />,
  'microphone-colored': <MicrophoneColored />,
  'mpe-logo-color': <MpeLogoColor />,
  'mpe-logo-white': <MpeLogoWhite />,
  musician: <Musician />,
  next: <Next />,
  pause: <Pause />,
  'pause-circle': <PauseCircle />,
  person: <Person />,
  play: <Play />,
  'play-bold-white': <PlayBoldWhite />,
  'play-circle': <PlayCircle />,
  'play-colored': <PlayColored />,
  plus: <Plus />,
  publication: <Publication />,
  publicationItem: <PublicationItem />,
  record: <Record />,
  'rotate-left': <RotateLeft />,
  search: <Search />,
  song: <Song />,
  star: <Star />,
  tag: <Tag />,
  tick: <Tick />,
  trash: <Trash />,
  turntable: <Turntable />,
  user: <User />,
  'user-sm': <UserSm />,
  venue: <Venue />,
  video: <Video />,
  'wave-colored': <WaveColored />
}
