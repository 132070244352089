export const SEARCH_CATEGORIES = {
  Galéria: 'albums',
  Hang: 'audio',
  Előadó: 'bands',
  Esemény: 'events',
  Eseménysorozat: 'event_series',
  Kép: 'images',
  Intézmény: 'institutions',
  Korszak: 'musical_periods',
  Személy: 'people',
  Kiadvány: 'publications',
  Kiadványelem: 'publication_items',
  Dal: 'songs',
  Címke: 'tags',
  Helyszín: 'venues',
  Videó: 'videos'
}
