import { createSlice } from '@reduxjs/toolkit'
import { VideoPlaylist } from '@mpe/api-client/models/video-playlist'
import { VideoTrack } from '@mpe/api-client/models/video-track'

import { RootState } from '../rootReducer'
import { statuses } from '@/consts/apiStatuses'

export interface VideoPlaylistsState {
  status: string
  data: VideoPlaylist[]
}

const initialState: VideoPlaylistsState = {
  status: statuses.UNLOADED,
  data: []
}

export const videoPlaylistsSlice = createSlice({
  name: 'videoPlaylists',
  initialState,
  reducers: {
    videoPlaylistRequest: (state) => {
      state.status = statuses.LOADING
    },
    createVideoPlaylistSuccess: (state) => {
      state.status = statuses.LOADED
    },
    createVideoPlaylistError: (state) => {
      state.status = statuses.ERROR
    },
    getVideoPlaylistAuthenticationError: (state) => {
      state.status = statuses.AUTHENTICATION_ERROR
    },
    addVideoPlaylistItemSuccess: (state, { payload }) => {
      state.status = statuses.LOADED
      state.data = payload || []
    },
    getVideoPlaylistsSuccess: (state, { payload }) => {
      state.data = payload || []
      state.status = statuses.LOADED
    },
    getVideoPlaylistError: (state) => {
      state.status = statuses.ERROR
    },
    deleteVideoPlaylistItemSuccess: (state, { payload }) => {
      state.status = statuses.LOADED
      const playlistIndex = state.data.findIndex((pl) => pl.tracks.find((tr) => tr.id === payload))
      if (playlistIndex > -1) {
        const trackIndex = state.data[playlistIndex].tracks.findIndex((tr) => tr.id === payload)
        state.data[playlistIndex].tracks.splice(trackIndex, 1)
      }
    },
    updateVideoPlaylistItemsOrderSuccess: (state, { payload }) => {
      const newState = state.data.map((p) =>
        p['@id'] !== payload.playlistIri
          ? p
          : {
              ...p,
              items: [...p.tracks].sort(
                (a: VideoTrack, b: VideoTrack) =>
                  payload.items.indexOf(a.id) - payload.items.indexOf(b.id)
              )
            }
      )
      state.status = statuses.LOADED
      state.data = newState
    },
    deleteVideoPlaylistSuccess: (state, { payload }) => {
      const newState = state.data.filter((p) => p.id !== payload)
      state.status = statuses.LOADED
      state.data = newState
    },
    updateVideoPlaylistSuccess: (state, { payload: { id, name, isPublic } }) => {
      state.status = statuses.LOADED
      state.status = statuses.LOADED
      const index = state.data.findIndex((p) => String(p.id) === String(id))
      if (index > -1) {
        state.data[index].name = name
        state.data[index].public = isPublic
      }
    }
  }
})

export const {
  createVideoPlaylistSuccess,
  getVideoPlaylistAuthenticationError,
  videoPlaylistRequest,
  createVideoPlaylistError,
  getVideoPlaylistsSuccess,
  addVideoPlaylistItemSuccess,
  deleteVideoPlaylistItemSuccess,
  updateVideoPlaylistItemsOrderSuccess,
  deleteVideoPlaylistSuccess,
  getVideoPlaylistError,
  updateVideoPlaylistSuccess
} = videoPlaylistsSlice.actions

export const videoPlaylistsSelector = (state: RootState) => state.videoPlaylists
export const videoPlaylistsStatus = (state: RootState) => state.videoPlaylists.status
export default videoPlaylistsSlice.reducer
