export const entityTypes: { [key: string]: string } = {
  Album: 'Galéria',
  Audio: 'Audió',
  Award: 'Díj',
  Band: 'Előadó',
  Event: 'Esemény',
  EventSeries: 'Eseménysorozat',
  Image: 'Kép',
  Institution: 'Intézmény',
  MusicalPeriod: 'Korszak',
  Person: 'Személy',
  Publication: 'Kiadvány',
  PublicationItem: 'Kiadványelem',
  Song: 'Dal',
  MusicAlbum: 'Album',
  Tag: 'Címke',
  Venue: 'Helyszín',
  Video: 'Videó'
}
