import { AudioPlaylist } from '@mpe/api-client/models/audio-playlist'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'

interface AudioPlayerState {
  //  aka selected for playing
  selected: {
    item: AudioPlaylist | null
    currentTrackId: string | null
    currentTrackPosition: number
    isPlaying: boolean
  }
}

const initialState: AudioPlayerState = {
  selected: {
    item: null,
    currentTrackId: null,
    currentTrackPosition: 0,
    isPlaying: false
  }
}

export const audioPlayerSlice = createSlice({
  name: 'audioPlayer',
  initialState,
  reducers: {
    updateSelectedCurrentTrackId: (state, { payload }) => {
      state.selected.currentTrackId = payload.trackId
      state.selected.currentTrackPosition = payload.trackPosition ?? 0

      if (state.selected.item) {
        localStorage.setItem(
          'playlist',
          JSON.stringify({ playlistId: state.selected.item['@id'], trackId: payload.trackId })
        )
      }
    },
    updateSelectedCurrentTrackPosition: (state, { payload }) => {
      state.selected.currentTrackPosition = payload.trackPosition ?? 0
    },

    setSelectedPlaylist: (state, { payload }) => {
      if (!payload) {
        localStorage.removeItem('playlist')
        state.selected = {
          item: null,
          currentTrackId: null,
          currentTrackPosition: 0,
          isPlaying: false
        }
      } else {
        state.selected.item = payload.item
        state.selected.currentTrackId = payload.trackId
        state.selected.currentTrackPosition = payload.trackPosition ?? 0
        state.selected.isPlaying = undefined !== payload.isPlaying ? payload.isPlaying : true

        localStorage.setItem(
          'playlist',
          JSON.stringify({ playlistId: payload.item['@id'], trackId: payload.trackId })
        )
      }
    },
    resetSelectedPlaylist: (state) => {
      state.selected.item = null
      state.selected.currentTrackId = null
      state.selected.currentTrackPosition = 0
      state.selected.isPlaying = false
      localStorage.removeItem('playlist')
    },
    stopSelectedPlaylist: (state) => {
      state.selected.isPlaying = false
    },
    restartSelectedPlaylist: (state) => {
      state.selected.isPlaying = true
    }
  }
})

export const {
  setSelectedPlaylist,
  stopSelectedPlaylist,
  restartSelectedPlaylist,
  resetSelectedPlaylist,
  updateSelectedCurrentTrackId,
  updateSelectedCurrentTrackPosition
} = audioPlayerSlice.actions

export const playlistSelectedPlaylistSelector = (state: RootState) => state.audioPlayer.selected

export default audioPlayerSlice.reducer
