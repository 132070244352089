export const themes: Array<{ link: string; title: string }> = [
  {
    link: '/korszak',
    title: 'Korszakok'
  },
  {
    link: '/eloado',
    title: 'Előadók'
  },
  {
    link: '/szemely',
    title: 'Személyek'
  },
  {
    link: '/esemeny',
    title: 'Események'
  },
  {
    link: '/esemenysorozat',
    title: 'Eseménysorozatok'
  },
  {
    link: '/helyszin',
    title: 'Helyszínek'
  },
  {
    link: '/kiadvany',
    title: 'Kiadványok'
  },
  {
    link: '/kiadvanyelem',
    title: 'Kiadványelemek'
  }
]
