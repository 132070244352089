import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { useAppDispatch } from '@/store/index'
import { authSelector, AuthState, CurrentUser } from '@/store/slices/authSlice'
import { show } from '@/store/slices/modalSlice'

import PrivacyPolicyModal from '../PrivacyPolicyModal'

export default function PrivacyModalTrigger() {
  const dispatch = useAppDispatch()
  const auth: AuthState = useSelector(authSelector)
  const router = useRouter()

  useEffect(() => {
    if (!auth.currentUser || auth.isLoading || auth.isError) {
      return
    }

    const user: CurrentUser = auth.currentUser
    const policyWarning = (user.policyUpdated || 0) > (user.policyAccepted || 0)

    if (policyWarning) {
      dispatch(show({ modalComponent: <PrivacyPolicyModal />, canBeClosed: false }))
    }
  }, [auth, router.query])

  return null
}
