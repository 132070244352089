import { createSlice } from '@reduxjs/toolkit'

import { AppThunk } from '../index'
import { RootState } from '../rootReducer'
import { createModelFromData } from '@mpe/api-client/utils/dataConverter'
import { addAudioPlaylistItemSuccess } from './audioPlaylistsSlice'
import { statuses } from '@/consts/apiStatuses'
import { getToken } from '../thunks/videoPlaylistsThunk'

export interface TracksState {
  creating: {
    status: string
  }
}

const initialState: TracksState = {
  creating: {
    status: statuses.UNLOADED
  }
}

export const tracksSlice = createSlice({
  name: 'tracks',
  initialState,
  reducers: {
    createTrackRequest: (state) => {
      state.creating.status = statuses.LOADING
    },
    createTrackSuccess: (state) => {
      state.creating.status = statuses.LOADED
    },
    createTrackError: (state) => {
      state.creating.status = statuses.ERROR
    }
  }
})

const { createTrackError, createTrackRequest, createTrackSuccess } = tracksSlice.actions

export const createTrack =
  (playlistIri: string, audioIri: string): AppThunk =>
  async (dispatch) => {
    dispatch(createTrackRequest())

    try {
      const token = getToken()

      const response = await fetch(`/api/audio-tracks`, {
        method: 'POST',
        body: JSON.stringify({ playlist: playlistIri, audio: audioIri }),
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (201 !== response.status) {
        throw new Error('Response status is not 201')
      }

      const data = await response.json()

      if ('success' !== data.status) {
        throw new Error('Invalid response status')
      }

      const item = createModelFromData(data.item)

      dispatch(createTrackSuccess())
      dispatch(addAudioPlaylistItemSuccess({ playlist: item, audio: audioIri }))

      return item
    } catch (error) {
      dispatch(createTrackError())
    }
  }

export const tracksSelector = (state: RootState) => state.tracks
export default tracksSlice.reducer
