import { useEffect } from 'react'
import { Provider } from 'react-redux'
import type { AppProps } from 'next/app'

import apiClient from '@mpe/api-client'
import GuardedComponent from '@/components/GuardedComponent'
import AudioPlayer from '@/components/AudioPlayer'
import store from '@/store/index'
import { getApiClientConfigFromEnv } from '@/utils/env-helper'
import { getUser } from '@/store/slices/authSlice'
import PrivacyModalTrigger from '@/components/PrivacyModalTrigger'

import '../styles/fonts.css'
import '../styles/globals.css'
import '../styles/animations.css'
import Script from 'next/script'

apiClient.setConfig(getApiClientConfigFromEnv())

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    store.dispatch(getUser())
  }, [])

  return (
    <>
      <Provider store={store}>
        <PrivacyModalTrigger />
        <AudioPlayer />

        {!pageProps.isGuarded && <Component {...pageProps} />}

        {pageProps.isGuarded && (
          <GuardedComponent>
            <Component {...pageProps} />
          </GuardedComponent>
        )}
      </Provider>
      <Script
        strategy="afterInteractive"
        id="gemius-measure-code"
      >{`var pp_gemius_identifier = 'B7ZArSLBM94tq9iRFKmptseyj1h888_I88II6cZDA1n.m7';
      // lines below shouldn't be edited
      function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || []; x[x.length]=arguments;};};
      gemius_pending('gemius_hit'); gemius_pending('gemius_event'); gemius_pending('gemius_init'); gemius_pending('pp_gemius_hit'); gemius_pending('pp_gemius_event'); gemius_pending('pp_gemius_init');
      (function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');
      gt.setAttribute('defer','defer'); gt.src=l+'://gahu.hit.gemius.pl/xgemius.js'; s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');`}</Script>
    </>
  )
}

export default MyApp
