import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../rootReducer'

interface Navigation {
  themesVisibility: boolean
  overlay: boolean
}

const initialState: Navigation = {
  themesVisibility: true,
  overlay: false
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    hideThemes: (state) => {
      state.themesVisibility = false
    },
    showThemes: (state) => {
      state.themesVisibility = true
    },
    showOverlay: (state) => {
      state.overlay = true
    },
    hideOverlay: (state) => {
      state.overlay = false
    }
  }
})

export const { hideThemes, showThemes, showOverlay, hideOverlay } = navigationSlice.actions
export const navigationSelector = (state: RootState) => state.navigation
export default navigationSlice.reducer
