import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../rootReducer'

interface Modal {
  open: boolean
  top: number
  content: JSX.Element[]
  canBeClosed: boolean
}

const initialState: Modal = {
  open: false,
  top: 0,
  content: [],
  canBeClosed: true
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    show: (state, { payload: { modalComponent, canBeClosed = true } }) => {
      state.open = true
      state.top = window.pageYOffset
      state.content?.push(modalComponent)
      state.canBeClosed = canBeClosed
    },
    hide: (state) => {
      // payload indicates a force flag
      if (!state.canBeClosed) {
        return
      }
      if (state.content.length === 1) {
        state.open = false
      }
      state.content.pop()
    },
    forceHide: (state) => {
      state.content = []
      state.open = false
    }
  }
})

export const { show, hide, forceHide } = modalSlice.actions
export const modalSelector = (state: RootState) => state.modal
export default modalSlice.reducer
