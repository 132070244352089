import { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AudioPlaylist } from '@mpe/api-client/models/audio-playlist'
import { AudioTrack } from '@mpe/api-client/models/audio-track'
import { createModelFromData } from '@mpe/api-client/utils/dataConverter'

import Icon from '../Icon'
import Image from '../Image'

import { authSelector } from '@/store/slices/authSlice'
import { formatDuration, imageWithWidth } from '@/utils'
import { handleAudioEvent } from '@/utils/google-tag-manager'

import 'plyr/dist/plyr.css'
import { Link } from '../Link/Link'
import {
  playlistSelectedPlaylistSelector,
  restartSelectedPlaylist,
  setSelectedPlaylist,
  stopSelectedPlaylist,
  updateSelectedCurrentTrackId,
  updateSelectedCurrentTrackPosition
} from '@/store/slices/audioPlayerSlice'

const AudioPlayer = () => {
  const dispatch = useDispatch()
  const auth = useSelector(authSelector)
  const selectedPlaylist = useSelector(playlistSelectedPlaylistSelector)

  const [currentTrack, setCurrentTrack] = useState<AudioTrack | null>(null)
  const [trackIndex, setTrackIndex] = useState(0)
  const [duration, setDuration] = useState(0)
  const [ended, setEnded] = useState(false)

  const audioRef = useRef<HTMLAudioElement | null>(null)
  const onPlay = function (this: HTMLAudioElement) {
    this.play()
  }
  const onTimeUpdate = function (this: HTMLAudioElement) {
    dispatch(
      updateSelectedCurrentTrackPosition({
        trackPosition: Math.floor(this.currentTime)
      })
    )
  }
  const onDurationChange = function (this: HTMLAudioElement) {
    setDuration(this.duration)
  }
  const onEnded = () => setEnded(true)
  const setRef = useCallback(
    (node: HTMLAudioElement) => {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.removeEventListener('canplaythrough', onPlay)
        audioRef.current.removeEventListener('timeupdate', onTimeUpdate)
        audioRef.current.removeEventListener('durationchange', onDurationChange)
        audioRef.current.removeEventListener('ended', onEnded)

        audioRef.current.removeEventListener('play', onDataLayerEvent)
        audioRef.current.removeEventListener('pause', onDataLayerEvent)
        audioRef.current.removeEventListener('ended', onDataLayerEvent)
        audioRef.current.removeEventListener('timeupdate', onDataLayerEvent)
        audioRef.current.removeEventListener('canplay', onDataLayerEvent)
      }

      if (node) {
        node.addEventListener('canplaythrough', onPlay)
        node.addEventListener('timeupdate', onTimeUpdate)
        node.addEventListener('durationchange', onDurationChange)
        node.addEventListener('ended', onEnded)

        if (selectedPlaylist.item?.tracks && selectedPlaylist?.currentTrackId) {
          const { tracks } = selectedPlaylist.item
          const trackIndex: number = selectedPlaylist.item.tracks
            .map((t: AudioTrack) => t['@id'])
            .indexOf(selectedPlaylist.currentTrackId)
          const track = tracks[trackIndex]

          if (track) {
            setCurrentTrack(track)
            setTrackIndex(trackIndex)
            try {
              node.src = track.audio.getUrl()
            } catch (e) {
              console.error('Unable to load track. Url was not found.')
            }
          }
        }
      }

      audioRef.current = node
    },
    [selectedPlaylist.currentTrackId]
  )

  const tryToRestorePlaylist = async () => {
    const playlistState = localStorage.getItem('playlist')
    const token = localStorage.getItem('user_token')

    if (!playlistState || !token || token === '') {
      return
    }

    try {
      const localStorageData = JSON.parse(playlistState)

      if (!localStorageData.playlistId || !localStorageData.trackId) {
        return
      }

      const url = localStorageData.playlistId.replace('_playlist', '-playlist')
      const response = await fetch(`/api${url}`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      const data = await response.json()
      const item = createModelFromData(data.item) as AudioPlaylist

      const track = item.tracks.find((t) => t['@id'] === localStorageData.trackId)

      if (track) {
        dispatch(
          setSelectedPlaylist({
            item,
            trackId: track['@id'],
            isPlaying: false
          })
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    tryToRestorePlaylist()
  }, [])

  useEffect(() => {
    if (audioRef.current) {
      try {
        if (!selectedPlaylist.isPlaying && !audioRef.current.paused) {
          audioRef.current.pause()
        }
        if (selectedPlaylist.isPlaying && audioRef.current.paused) {
          audioRef.current.play()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [selectedPlaylist.isPlaying, selectedPlaylist.currentTrackId, audioRef.current])

  useEffect(() => {
    if (ended) {
      const tracks = selectedPlaylist.item?.tracks.map((track: AudioTrack) => track['@id'])
      if (tracks && trackIndex < tracks.length - 1) {
        const nextTrackId = tracks[trackIndex + 1]
        dispatch(updateSelectedCurrentTrackId({ trackId: nextTrackId }))
        setEnded(false)
      }
    }
  }, [ended])

  const onDataLayerEvent = (event: any, obj: any = null) => {
    const track: AudioTrack = obj?.track || (currentTrack as AudioTrack)
    if (!track) {
      return
    }

    handleAudioEvent(
      track.audio.fileName,
      obj?.type || event.type,
      audioRef.current as HTMLAudioElement,
      track.audio
    )
  }

  useEffect(() => {
    if (!currentTrack || !audioRef.current) {
      return
    }

    const audio: HTMLAudioElement = audioRef.current
    const dataLayerEvents = ['play', 'pause', 'ended', 'timeupdate', 'canplay']
    dataLayerEvents.forEach((item: any) => {
      audio.removeEventListener(item, onDataLayerEvent)
      audio.addEventListener(item, onDataLayerEvent)
    })

    return () => {
      dataLayerEvents.forEach((item: any) => {
        audio.removeEventListener(item, onDataLayerEvent)
      })
    }
  }, [selectedPlaylist.currentTrackId, currentTrack])

  const moveTrack = (back?: boolean) => {
    if (!selectedPlaylist.item) {
      return
    }

    let track
    if (back && trackIndex) {
      track = selectedPlaylist.item?.tracks[trackIndex - 1]
      onDataLayerEvent(null, { type: 'pause' })
      onDataLayerEvent(null, { type: 'backward', track })
    }
    if (!back && trackIndex < selectedPlaylist.item?.tracks.length - 1) {
      track = selectedPlaylist.item?.tracks[trackIndex + 1]
      onDataLayerEvent(null, { type: 'pause' })
      onDataLayerEvent(null, { type: 'forward', track })
    }
    if (track) {
      dispatch(updateSelectedCurrentTrackId({ trackId: track['@id'] }))
    }
  }

  const onPlaylistPlayClick = () => {
    if (selectedPlaylist.isPlaying) {
      dispatch(stopSelectedPlaylist())
    } else {
      dispatch(restartSelectedPlaylist())
    }
  }

  const onCancelClick = () => {
    dispatch(setSelectedPlaylist(null))

    if (currentTrack && selectedPlaylist.isPlaying) {
      onDataLayerEvent(null, { type: 'pause' })
    }
  }

  return (
    <>
      {selectedPlaylist.item && (
        <div className="w-full z-30 fixed bottom-0 py-2 flex items-center px-1 lg:px-4 h-24 bg-mostlyBlack">
          <audio ref={setRef} style={{ display: 'none' }} />
          <div className="flex items-center w-full h-full relative container-padded">
            <div className="w-14 h-14 relative mr-2 border border-white">
              {currentTrack?.audio.defaultImageUrl ? (
                <Image useSrcLoader src={imageWithWidth(currentTrack.audio.defaultImageUrl, 80)} />
              ) : (
                <div className="flex w-full h-full items-center justify-center bg-gradient-to-tr from-tomato to-indigo">
                  <Icon name="song" className="w-4 h-4" disableHover />
                </div>
              )}
            </div>
            <div className="overflow-hidden text-ellipsis z-50 max-w-xs">
              <Link href={`/audio-${currentTrack?.audio?.name}-${currentTrack?.audio?.id}`}>
                <span className="block font-bold mb-1 cursor-pointer truncate">
                  {currentTrack?.audio?.name}
                </span>
              </Link>
              <Link href={`/lejatszasi-listak/audios/${selectedPlaylist.item.id}`}>
                <span className="text-sm block cursor-pointer">{selectedPlaylist.item.name}</span>
              </Link>
            </div>
            <div className="lg:absolute lg:w-full flex items-end lg:items-center flex-col z-40 ml-auto lg:ml-0">
              <div className="flex items-center">
                <div
                  className={`hidden lg:flex bg-gradient-to-tr from-tomato to-indigo w-6 h-6 rounded-full items-center justify-center mr-2 ${
                    !trackIndex ? 'opacity-50' : 'cursor-pointer'
                  }`}
                >
                  <Icon
                    name="next"
                    className="w-5 h-5 transform rotate-180"
                    onClick={() => moveTrack(true)}
                  />
                </div>
                <div className="bg-gradient-to-tr from-tomato to-indigo w-10 h-10 rounded-full flex items-center justify-center cursor-pointer">
                  <Icon
                    name={`${selectedPlaylist.isPlaying ? 'pause' : 'play'}`}
                    className={`w-5 h-5 ${selectedPlaylist.isPlaying ? '' : 'ml-1'}`}
                    onClick={onPlaylistPlayClick}
                  />
                </div>
                <div
                  className={`hidden lg:flex bg-gradient-to-tr from-tomato to-indigo w-6 h-6 rounded-full items-center justify-center ml-2 ${
                    trackIndex === selectedPlaylist.item.tracks.length - 1
                      ? 'opacity-50'
                      : 'cursor-pointer'
                  }`}
                >
                  <Icon name="next" className="w-5 h-5" onClick={() => moveTrack()} />
                </div>
              </div>
              <div className="absolute -bottom-1 left-0 right-0 lg:relative flex items-center mt-1">
                <div className="hidden lg:flex text-sm w-12 justify-center">
                  {formatDuration(selectedPlaylist.currentTrackPosition)}
                </div>
                <div className="w-full lg:w-96 h-0.5 bg-darkGray">
                  <div
                    className="h-full bg-gradient-to-r from-tomato to-indigo transition-all"
                    style={{
                      width: `${(selectedPlaylist.currentTrackPosition / duration) * 100}%`
                    }}
                  />
                </div>
                <div className="hidden lg:flex text-sm w-12 justify-center">
                  {formatDuration(duration)}
                </div>
              </div>
            </div>
            <div className="absolute -top-4 left-0 lg:top-auto lg:left-auto lg:relative ml-auto w-6 h-6 cursor-pointer z-50 bg-mostlyBlack flex items-center justify-center rounded-full">
              <Icon name="cancel" className="w-3 h-3 lg:w-4 lg:h-4" onClick={onCancelClick} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AudioPlayer
