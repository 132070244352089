import { forwardRef, RefObject, cloneElement, MouseEvent } from 'react'

import classes from './Icon.module.css'

import { iconMapping } from '@/consts'

interface IconProps {
  name: string
  className?: string
  onClick?: (e: MouseEvent<HTMLElement>) => void
  fill?: boolean
  disableHover?: boolean
  style?: { [key: string]: any }
}

// Need to wrap in forwardRef because of <Link>: https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component
const Icon = forwardRef(
  ({ name, className, onClick, fill, disableHover, style }: IconProps, ref) => {
    return (
      <div
        className={`${className} ${disableHover ? classes.disableHover : ''}`}
        onClick={onClick}
        ref={ref as RefObject<HTMLDivElement>}
        style={style}
      >
        {iconMapping[name]
          ? cloneElement(iconMapping[name], {
              className: `${classes.svgItem} ${fill ? `${classes.filled}` : ''}`
            })
          : null}
      </div>
    )
  }
)

export default Icon
