import { MouseEvent } from 'react'

interface ButtonProps {
  children: React.ReactNode
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  className?: string
  type?: 'submit' | 'button'
  disabled?: boolean
}

const Button = ({ children, onClick, className, type = 'button', disabled }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`p-2 rounded-full font-semibold shadow-md transition-all ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
