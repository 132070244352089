import { createSlice } from '@reduxjs/toolkit'
import { VideoPlaylist } from '@mpe/api-client/models/video-playlist'
import { Video } from '@mpe/api-client/models/entities/video'

import { RootState } from '../rootReducer'

interface VideoPlayerState {
  data: VideoPlaylist | undefined
  currentVideo: Video | undefined
  currentIndex: number
  currentSegment: number
}

const initialState: VideoPlayerState = {
  data: undefined,
  currentVideo: undefined,
  currentIndex: 0,
  currentSegment: 1
}

export const videoPlayerSlice = createSlice({
  name: 'videoPlayer',
  initialState,
  reducers: {
    selectPlaylist: (state, { payload }) => {
      state.data = payload
      state.currentIndex = 0
      state.currentVideo = payload.items?.[0]?.video
    },
    setCurrentVideo: (state, { payload }) => {
      state.currentIndex = payload
      state.currentVideo = state.data?.tracks?.[payload].video
    },
    setCurrentSegment(state, { payload }) {
      return { ...state, currentSegment: payload }
    },
    resetPlayer: (state) => {
      state.data = undefined
      state.currentVideo = undefined
      state.currentIndex = 0
      state.currentSegment = 1
    }
  }
})

export const { selectPlaylist, setCurrentVideo, setCurrentSegment, resetPlayer } =
  videoPlayerSlice.actions
export const videoPlayerSelector = (state: RootState) => state.videoPlayer
export default videoPlayerSlice.reducer
