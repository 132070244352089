export { PATH_TO_ENTITY } from './pathToEntity'
export type { Metadata } from './pathToEntity'
export { SEARCH_CATEGORIES } from './searchCategories'
export { errorMessages } from './errorMessages'
export { entityTypes } from './entityTypes'
export { themes } from './themes'
export { iconMapping } from './iconMapping'

export const ENTITIES_PER_PAGE = 64
export const DEFAULT_PLAYLIST = 'Utoljára hallgatottak'
export const DEFAULT_VIDEO_PLAYLIST = 'Utoljára megnézettek'
export const primaryTypes = ['Album', 'Band', 'Person', 'MusicalPeriod', 'Publication']
export const mediaTypes = ['Audio', 'Video', 'Image', 'Audio']
