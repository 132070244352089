import slug from 'slug'
import { Entity as ApiClientEntity } from '@mpe/api-client/models/entities/entity'
import personRoleOptions from '@mpe/api-client/config/personRoleOptions'
import { RequestArguments } from '@mpe/api-client/types'

import { ENTITIES_PER_PAGE, themes } from '../consts'
import { entityTypes } from '../consts'

export function getTypeString(type: string): string {
  return entityTypes[type] || ''
}

export const entityIcons: { [key: string]: string } = {
  Album: 'album',
  Audio: 'audio',
  Award: 'award',
  Band: 'band',
  Event: 'event',
  EventSeries: 'event-series',
  Image: 'image',
  Institution: 'building',
  MusicalPeriod: 'era',
  Person: 'person',
  Publication: 'publication',
  PublicationItem: 'publicationItem',
  Song: 'song',
  Tag: 'tag',
  Venue: 'venue',
  Video: 'video',
  default: 'musician'
}

export function getTypeIcon(type: string): string {
  return entityIcons[type] || entityIcons.default
}

export function getEntitySlug(entity: ApiClientEntity<any>): string {
  if (entity.name) {
    return slug(`${getCategorySlug(String(getEntityType(entity)))}-${entity.name}-${entity.id}`)
  } else {
    // Fall back to ID. ¯\_(ツ)_/¯
    return slug(entity.id.toString())
  }
}

export function getCategorySlug(category: string): string {
  const localizedCategoryName = entityTypes[category]
  return slug(localizedCategoryName)
}

export function getEntityUrl(entity: ApiClientEntity<any>): string {
  return `/${getEntitySlug(entity)}`
}

export function getIdFromSlug(slug: string): string {
  const slugParts = slug.split('-')
  return slugParts[slugParts.length - 1]
}

export function getEntityType(entity: ApiClientEntity<any>): string | number {
  // Some random entity types miss the normal 'type' property
  return entity.type || entity['@type']
}

export function requestArgBuilder(
  slug: string[],
  search?: string,
  appendQuery?: {}
): RequestArguments {
  let requestArgs: RequestArguments = { page: '1', itemsPerPage: String(ENTITIES_PER_PAGE) }

  if (slug) {
    const [page, sort, order] = slug
    if (page) {
      requestArgs.page = page
    }
    if (sort && order) {
      requestArgs[`order[${sort}]`] = order
    }
    if (search && search.length > 1) {
      requestArgs.name = search
    }
  }

  if (appendQuery) {
    requestArgs = {
      ...requestArgs,
      ...appendQuery
    }
  }

  return requestArgs
}

export const isThemePath = (asPath: string) =>
  themes.map((t) => t.link.split('/')[1]).includes(asPath.split('/')[1])

export function scrollTo(el?: HTMLElement | null, offset?: number): void {
  const offsetTop: number = el?.offsetTop || 0

  window.scrollTo({
    top: offsetTop + (offset || 0),
    behavior: 'smooth'
  })
}

export const scrollToTop = () => scrollTo()

export const formatDateString = (dateString: string, onlyYear?: boolean): string => {
  const date = new Date(dateString)
  if (date) {
    if (onlyYear) {
      return date.getFullYear().toString()
    }
    return new Intl.DateTimeFormat('hu-HU').format(date)
  }
  return ''
}

export const formatDuration = (duration: number, withText?: boolean) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - hours * 3600) / 60)
  const seconds = Math.floor(duration % 60)

  if (withText) {
    return `${hours ? `${hours} óra ` : ''}${minutes} perc`
  }

  return `${hours ? `${hours}:` : ''}${minutes > 9 ? minutes : `0${minutes}`}:${
    seconds > 9 ? seconds : `0${seconds}`
  }`
}

export { imageWithWidth } from './cloudinary'

export const getRoleLabels = (value: string | null): string | null => {
  if (!value || value === '') {
    return null
  }

  const roleValues = value.split(';')
  const roles: any = []
  roleValues.forEach((roleItem) => {
    const item = personRoleOptions.find((optionItem: any) => optionItem.value === roleItem)
    if (item) roles.push(item.name)
  })

  if (roles.length === 0) {
    return null
  }

  return roles.join(', ')
}

export const getStringAsRichText = (original?: string): string | undefined => {
  if (!original) {
    return original
  }

  if (!original.includes('<p>')) {
    return '<p>' + original.replace(/\n/g, '</p><p>') + '</p>'
  }

  return original
}
