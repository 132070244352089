import { createModelFromData, createModelListFromData } from '@mpe/api-client/utils/dataConverter'

import { AppThunk } from '../index'
import {
  videoPlaylistRequest,
  createVideoPlaylistError,
  createVideoPlaylistSuccess,
  getVideoPlaylistsSuccess,
  deleteVideoPlaylistSuccess,
  updateVideoPlaylistSuccess,
  updateVideoPlaylistItemsOrderSuccess,
  getVideoPlaylistAuthenticationError,
  getVideoPlaylistError,
  addVideoPlaylistItemSuccess,
  deleteVideoPlaylistItemSuccess
} from '../slices/videoPlaylistsSlice'

export const createVideoPlaylist =
  (name: string, isPublic = false): AppThunk =>
  async (dispatch, getState) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken()

      const response = await fetch('/api/video-playlists', {
        method: 'POST',
        body: JSON.stringify({ name, isPublic }),
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (201 !== response.status) {
        throw new Error('Response status is not 201')
      }

      dispatch(createVideoPlaylistSuccess())

      const playlist = await response.json()
      const playlists = [...getState().videoPlaylists.data, createModelFromData(playlist)]

      return { playlist, playlists }
    } catch (error) {
      console.error(error)
      dispatch(createVideoPlaylistError())
    }
  }

export const getVideoPlaylist =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken(true)

      const response = await fetch(
        `/api/video-playlists/${id}`,
        token
          ? {
              method: 'GET',
              headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token}`
              }
            }
          : undefined
      )

      if (401 === response.status) {
        dispatch(getVideoPlaylistAuthenticationError())
        return
      }

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      const data = await response.json()
      const convData = createModelFromData(data)
      dispatch(getVideoPlaylistsSuccess([convData]))
      return convData
    } catch (error) {
      console.error(error)
      dispatch(createVideoPlaylistError())
    }
  }

export const getVideoPlaylists = (): AppThunk => async (dispatch) => {
  dispatch(videoPlaylistRequest())

  try {
    const token = getToken()
    if (!token) {
      throw new Error('Token not found')
    }

    const items = await getVideoPlaylistsHttp(token)
    dispatch(getVideoPlaylistsSuccess(items))
  } catch (error) {
    console.error(error)
    dispatch(createVideoPlaylistError())
  }
}

export const updateEntityVideoPlaylistsRelation =
  (playlistIds: String[], entityId: number): AppThunk =>
  async (dispatch) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken()
      if (!token) {
        throw new Error('Token not found')
      }

      const response = await fetch(`/api/video-tracks/${entityId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ playlists: playlistIds })
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      dispatch(addVideoPlaylistItemSuccess(await getVideoPlaylistsHttp(token)))
    } catch (error) {
      console.error(error)
      dispatch(createVideoPlaylistError())
    }
  }

export const addVideoPlaylistItem =
  (playlistIri: string, entityId: number): AppThunk =>
  async (dispatch) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken()

      const response = await fetch(`/api/video-tracks/${entityId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ playlists: [playlistIri] })
      })

      if (![200, 201].includes(response.status)) {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    } catch (error) {
      console.error(error)
      dispatch(createVideoPlaylistError())
    }
  }

export const deleteVideoTrack =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken()

      const response = await fetch(`/api/video-tracks/${id}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (204 !== response.status) {
        throw new Error('Response status is not 204')
      }

      dispatch(deleteVideoPlaylistItemSuccess(id))
    } catch (error) {
      console.error(error)
      dispatch(createVideoPlaylistError())
    }
  }

export const updateVideoPlaylistItemsOrder =
  (playlistIri: string, items: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken()

      const response = await fetch('/api/video-tracks/order', {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ items })
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      dispatch(updateVideoPlaylistItemsOrderSuccess({ items, playlistIri }))
    } catch (error) {
      console.error(error)
      dispatch(createVideoPlaylistError())
    }
  }

export const deleteVideoPlaylist =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken()

      const response = await fetch(`/api/video-playlists/${id}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (204 !== response.status) {
        throw new Error('Response status is not 204')
      }

      dispatch(deleteVideoPlaylistSuccess(id))
    } catch (error) {
      console.error(error)
      dispatch(createVideoPlaylistError())
    }
  }

export const updateVideoPlaylist =
  (id: string, name: string, isPublic = false): AppThunk =>
  async (dispatch) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken()

      const response = await fetch(`/api/video-playlists/${id}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ name, isPublic })
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      dispatch(updateVideoPlaylistSuccess({ id, name, isPublic }))
    } catch (error) {
      console.error(error)
      dispatch(createVideoPlaylistError())
    }
  }

export const updateVideoPlaylists =
  (id: string, playlists: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(videoPlaylistRequest())

    try {
      const token = getToken()
      if (!token) {
        throw new Error('Token not found')
      }

      const response = await fetch(`/api/video-tracks/${id}`, {
        body: JSON.stringify({ playlists }),
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      const items = await getVideoPlaylistsHttp(token)
      dispatch(getVideoPlaylistsSuccess(items))
      return true
    } catch (error) {
      console.error(error)
      dispatch(getVideoPlaylistError())
    }

    return false
  }

export const getToken = (silent = false): string | undefined => {
  const token = localStorage.getItem('user_token')
  if (token && token !== '') {
    return token
  }

  if (silent) {
    return undefined
  }

  throw new Error('Token not found')
}

const getVideoPlaylistsHttp = async (token: string): Promise<any> => {
  const response = await fetch(`/api/video-playlists`, {
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    }
  })

  if (200 !== response.status) {
    throw new Error('Response status is not 200')
  }

  const { data } = await response.json()
  return createModelListFromData(data.items)
}
