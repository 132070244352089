import getConfig from 'next/config'

export const getApiClientConfigFromEnv = () => {
  const runtimeConfig = getConfig().publicRuntimeConfig ?? {}

  return {
    url: String(runtimeConfig.apiHost ?? 'https://mpe-admin.passeum.com').trim(),
    s3Url: String(runtimeConfig.s3Url ?? 'http://localhost:9000/mpe').trim(),
    debug: 'true' === String(runtimeConfig.apiClientDebug)
  }
}
