import Plyr from 'plyr'
import getConfig from 'next/config'
import { IAudio } from '@mpe/api-client/models/entities/audio'
import { IVideo } from '@mpe/api-client/models/entities/video'
import useDataLayer from '@/hooks/useDataLayer'

const runtimeConfig = getConfig().publicRuntimeConfig ?? {}
const gtmConfig = {
  id: runtimeConfig.gtm || false,
  consoleDebug: runtimeConfig.gtmConsoleDebug || false
}

const addToDataLayer = (obj: any) => {
  const dataLayer = useDataLayer()
  dataLayer.push(obj as never)

  if (gtmConfig.consoleDebug) {
    console.log('GTM, added to data layer', obj)
  }
}

// We're going to save our players status on this object.
const divisor = 25
const elementStatuses: any = {}

export const initStatusObject = (id: string) => {
  // Video Status Object declaration
  const statObject = {
    // On page DOM, all players currentTime is 0
    current: 0,
    // We'll save the highest percent mark played by the user in the current video.
    greatest_marker: 0,
    can_play: false,
    _progress_markers: {}
  }

  const markers: any = {}
  // Let's set the progress markers, so we can know afterwards which ones have been already sent.
  for (let j = 0; j < 100; j++) {
    const progress_point = divisor * Math.floor(j / divisor)
    markers[progress_point] = false
  }

  statObject._progress_markers = markers
  elementStatuses[id] = statObject
}

export const clearStatusObject = (id: string) => {
  delete elementStatuses[id]
}

export const handleStatus = (
  statusObj: any,
  type: string,
  playerData: { currentTime: number; duration: number }
) => {
  switch (type) {
    // This event type is sent everytime the player updated it's current time,
    // We're using for the % of the video played.
    case 'timeupdate':
      // Let's set the save the current player's video time in our status object
      statusObj.current = Math.round(playerData.currentTime)

      // We just want to send the percent events once
      // eslint-disable-next-line no-case-declarations
      const pct = Math.floor((100 * statusObj.current) / playerData.duration)
      for (const j in statusObj._progress_markers) {
        const jpercent = parseInt(j)
        if (pct >= jpercent && jpercent > statusObj.greatest_marker) {
          statusObj.greatest_marker = jpercent
        }
      }

      // current bucket hasn't been already sent to GA?, let's push it to GTM
      if (statusObj.greatest_marker && !statusObj._progress_markers[statusObj.greatest_marker]) {
        statusObj._progress_markers[statusObj.greatest_marker] = true
        return 'progress'
      }

      break

    // This event is fired when user's click on the play button
    case 'play':
      return 'play'

    // This event is fied when user's click on the pause button
    case 'pause':
      if (statusObj.greatest_marker < '75') {
        return 'pause'
      }
      break

    // If the user ends playing the video, on Finish video will be pushed ( This equals to % played = 100 )
    case 'ended':
      return 'complete'

    case 'forward':
      return 'forward'

    case 'backward':
      return 'backward'
  }

  return null
}

export const handleAudioEvent = (
  id: string,
  type: string,
  audioElement: HTMLAudioElement,
  entity: IAudio
) => {
  if (!elementStatuses[id]) {
    initStatusObject(id)
  }

  const statusObj: any = elementStatuses[id]
  const data: any = {
    event: 'html5_audio',
    audio_status: '',
    audio_provider: 'generic html5 audio tag',
    audio_percent: statusObj.greatest_marker,
    audio_title: entity.fileName,
    audio_name: `[${entity.id}] ${entity.name}`,
    audio_src: entity.fileName
  }

  let status = handleStatus(statusObj, type, {
    currentTime: audioElement.currentTime,
    duration: audioElement.duration
  })

  if (type === 'canplay') {
    status = 'opened'
  }

  if (!status) {
    return
  }

  data.audio_status = status
  data.audio_percent = statusObj.greatest_marker

  addToDataLayer(data)
}

// This is the funcion that is gonna handle the event sent by the player listeners
export const handleVideoEvent = (id: string, type: string, player: Plyr, entity: IVideo) => {
  if (!elementStatuses[id]) {
    initStatusObject(id)
  }

  const statusObj: any = elementStatuses[id]
  const data: any = {
    event: 'html5_video',
    video_status: '',
    video_provider: 'Plyr 3.5',
    video_percent: statusObj.greatest_marker,
    video_title: entity.videoName,
    video_name: `[${entity.id}] ${entity.name}`,
    video_src: entity.videoName
  }

  let status = handleStatus(statusObj, type, {
    currentTime: player.currentTime,
    duration: player.duration
  })

  if (type === 'canplay' && !statusObj.can_play) {
    statusObj.can_play = true
    status = 'opened'
  }

  if (!status) {
    return
  }

  data.video_status = status
  data.video_percent = statusObj.greatest_marker

  addToDataLayer(data)
}

export const isGTMAvailable = () => {
  return gtmConfig.id !== false
}

export const getHeadScript = () => {
  return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-${gtmConfig.id}');`
}

export const getBodyScript = () => {
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${gtmConfig.id}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`
}
