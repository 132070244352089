import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { authSelector } from '@/store/slices/authSlice'

const GuardedComponent: React.FC<any> = ({ children }) => {
  const router = useRouter()
  const auth = useSelector(authSelector)

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuth) {
      router.push('/bejelentkezes')
    }
  }, [auth])

  if (auth.isLoading || !auth.isAuth) {
    return null
  }

  return children
}

export default GuardedComponent
